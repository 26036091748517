import React, { useEffect, useState } from "react";
import AppSectionLoader from "../common-components/AppSectionLoader";
import Chart from "react-apexcharts";
import FeatherIcon from "feather-icons-react";
import { localStorageCompanyId, publicUrl } from "../config/appConstant";

const DashboardSummarySection = ({
  detailPopUp,
  dashboardSummaryResModel,
  showSectionLoading,
  showSectionLoadingChart,
  month,
  doctor,
  hygienes,
  getProChart,
  theme,
  logos,
}) => {
  const dummySummaryData = {
    DoctorProduction: {
      Value: "$0",
      ImageURL: "",
      Title: "Doctor Production",
      ToolTip: "Doctor Production",
      IsRowData: false,
      Module: 1,
      OrderBy: 1,
      SectionId: 1,
    },
    Collections: {
      Value: "$0",
      ImageURL: "",
      Title: "Collections",
      ToolTip: "Collections",
      IsRowData: false,
      Module: 2,
      OrderBy: 2,
      SectionId: 2,
    },
    Procedures: {
      Value: "0",
      ImageURL: "",
      Title: "Procedures",
      ToolTip: "Procedures",
      IsRowData: false,
      Module: 3,
      OrderBy: 3,
      SectionId: 3,
    },
    NewPatients: {
      ImageURL: "",
      IsRowData: false,
      Title: "Hygiene",
      ToolTip: "Hygiene",
      Value: "0",
      OrderBy: 4,
      SectionId: 4,
      Module: 4,
    },
    ActivePatientsWidget: {
      ImageURL: "",
      IsRowData: false,
      Title: "Active Patients",
      ToolTip: "Active Patients",
      Value: "0",
      OrderBy: 5,
      SectionId: 5,
      Module: 5,
    },
    InActivePatientsWidget: {
      ImageURL: "",
      IsRowData: false,
      Title: "Inactive Patients",
      ToolTip: "Inactive Patients",
      Value: "0",
      OrderBy: 6,
      SectionId: 6,
      Module: 6,
    },
    AccountReceivable: {
      ImageURL: "",
      IsRowData: false,
      Title: "Accounts Receivable",
      ToolTip: "Accounts Receivable",
      Value: "0",
      OrderBy: 7,
      SectionId: 7,
      Module: 7,
    },
    Hygiene: {
      Value: "0",
      ImageURL: "",
      Title: "Hygiene",
      ToolTip: "Hygiene",
      IsRowData: false,
      Module: 8,
      OrderBy: 8,
      SectionId: 8,
    },
  };
  const [dashboardSummaryData, setDashboardSummaryData] = useState(dummySummaryData);
  const companyId = localStorageCompanyId;
  const dummyDataDoctorHygiene = {
    series: [
      {
        name: "Doctor",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Hygiene ",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 345,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [theme.chartColor.Doctor, theme.chartColor.Hygiene],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      xaxis: {
        categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        title: {
          text: "Days",
        },
        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: "Production ($1000)",
        },
        axisBorder: {
          show: true,
          color: " #c8c8c8",
          offsetX: 0,
          offsetY: 0,
        },
      },

      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },

        y: {
          show: false,
        },
        x: {
          show: false,
        },
        style: {
          fontSize: "12px",
        },
      },
      fill: {
        opacity: 1,
        colors: [theme.chartColor.Doctor, theme.chartColor.Hygiene],
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: 40,
      },
    },
  };
  useEffect(() => {
    setDashboardSummaryData(dashboardSummaryResModel);
  }, [dashboardSummaryResModel]);

  return (
    <div id="blue-card" className="calender-box pt-0">
      <div className="container-fluid">
        <div className="row calender-row">
          <div className="col-xl-5 col-md-12 col-12 padding-r-4 calender-col">
            <div className="row">
              <div className=" col-lg-6  col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.DoctorProduction)}>
                      {dashboardSummaryData.DoctorProduction.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.DoctorProduction.Value}</p>
                    </div>
                    <div>
                      <img
                        src={`${publicUrl}/${companyId}/images/barChart.png`}
                        alt="Total Production"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.Collections)}>
                      {dashboardSummaryData.Collections.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.Collections.Value}</p>
                    </div>
                    <div>
                      <img src={logos.Card2} alt="Collections" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.Procedures)}>
                      {dashboardSummaryData.Procedures.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.Procedures.Value}</p>
                    </div>
                    <div>
                      <img src={logos.Card3} alt="Procedures" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.NewPatients)}>
                      {dashboardSummaryData.NewPatients.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.NewPatients.Value}</p>
                    </div>
                    <div>
                      <img src={logos.Card4} alt="New Patients" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.ActivePatientsWidget)}>
                      {dashboardSummaryData.ActivePatientsWidget.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.ActivePatientsWidget.Value}</p>
                    </div>
                    <div>
                      <img src={logos.Card4} alt="Active Patients" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4">
                <div className="card blue-card">
                  <div className="header">
                    <h6 onClick={() => detailPopUp(dashboardSummaryData.InActivePatientsWidget)}>
                      {dashboardSummaryData.InActivePatientsWidget.Title}
                      <FeatherIcon
                        className="h6 mb-0 text-white ml-1"
                        icon="alert-circle"
                        size="20"
                      />{" "}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>{dashboardSummaryData.InActivePatientsWidget.Value}</p>
                    </div>
                    <div>
                      <img src={logos.Card4} alt="Inactive Patients" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={!showSectionLoading ? "d-none" : ""}>
              <AppSectionLoader />
            </div>
          </div>
          <div className="col-xl-7 col-md-12 col-12 padding-l-4 calender-col prod-col">
            <div className="white-card pb-0 stack-box pl-3">
              <h6 className="blue-title-h6 position-absolute">Production</h6>
              <Chart
                id="#chart-stack"
                options={
                  month.length > 0
                    ? getProChart(month, doctor, hygienes).options
                    : dummyDataDoctorHygiene.options
                }
                series={
                  month.length > 0
                    ? getProChart(month, doctor, hygienes).series
                    : dummyDataDoctorHygiene.series
                }
                type="bar"
                height={545}
              />
            </div>
            <div className={!showSectionLoadingChart ? "d-none" : ""}>
              <AppSectionLoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSummarySection;
