import axios from "axios";
import { API_URL, HEADER } from "../config/appConstant";
class Api {
  // API for get Doctors
  static getProvider = async (AccountId) => {
    return await axios.post(
      API_URL + "/report/LoadDoctors",
      {
        AccountId: AccountId,
      },
      HEADER
    );
  };

  static getDashboardSummary = async (model) => {
    return await axios.post(API_URL + "/report/LoadDashboardSummary", model, HEADER);
  };

  static getReferralCount = async (model) => {
    return await axios.post(API_URL + "/report/ReferralCount", model, HEADER);
  };

  static getProductionChart = async (model) => {
    return await axios.post(API_URL + "/report/LoadProductionChart", model, HEADER);
  };

  static getWidgetsDetails = (model, sectionId) => {
    let obj = {
      AccountId: model.AccountId,
      SectionId: sectionId,
      UserId: model.UserId,
      ChartType: model.ChartType,
      StartDate: model.StartDate,
      EndDate: model.EndDate,
    };
    return axios.post(API_URL + "/report/LoadWidgetsDetails", obj, HEADER);
  };

  static postGoal = (model) => {
    return axios.post(API_URL + "/report/AddEditProductionGoal", model, HEADER);
  };
  static downloadDashboardSummary = (data) => {
    return axios.post(API_URL + "/report/DownloadDashboardSummary", data, HEADER);
  };
}
export default Api;
