import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import history from "../history";
import Icon1 from "../assets/images/icon-2.png";
import Icon2 from "../assets/images/eye.png";
import Icon3 from "../assets/images/logo-white.png";
import { validPasswordRegex } from "../config/regex";
import queryString from "query-string";
import { Spinner } from "reactstrap";
import Api from "../API/login";
import { validateForm, goToSignin } from "../config/commonFun";

export default function ResetPasswordPage() {
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCPasswordType] = useState("password");
  const [encEmail, setEncEmail] = useState("");
  // Response message
  const [rMessage, setRMessage] = useState("");
  // Loader
  const [loader, setLoader] = useState(false);

  const [eyeTog2, seteyeTog2] = useState("false");
  const newPassword = () => {
    seteyeTog2(!eyeTog2);
    passwordType == "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  const [eyeTog3, seteyeTog3] = useState("false");
  const cnewPassword = () => {
    seteyeTog3(!eyeTog3);
    cpasswordType == "password"
      ? setCPasswordType("text")
      : setCPasswordType("password");
  };

  // get encrypted email
  useEffect(() => {
    const qString = queryString.parse(window.location.search);
    if (qString != null && qString.Eamil != undefined) {
      setModel((model) => ({
        ...model,
        ["Email"]: qString.Eamil,
      }));
    } else {
      //goToSingIn();
    }
  }, []);

  //
  const [model, setModel] = useState({
    Email: "",
    Password: "",
    CPassword: "",
  });

  const [errors, setErrors] = useState({
    Password: "",
    CPassword: "",
  });

  // form control change event
  const handelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setModel((model) => ({
      ...model,
      [name]: value,
    }));
    switch (name) {
      case "Password":
        if (value.trim().length == 0) {
          errorValue = "New Password required.";
        } else {
          errorValue = validPasswordRegex.test(value)
            ? ""
            : "Enter valid Password.";
        }
        break;
      case "CPassword":
        if (value.trim().length == 0) {
          errorValue = "Confirm Password required.";
        } else {
          errorValue = model.Password == value ? "" : "Password don't match.";
        }
        break;
      default:
        break;
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (model.Password.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["Password"]: "New Password required.",
      }));
      flagError = true;
    }
    if (model.CPassword.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["CPassword"]: "Confirm Password required.",
      }));
      flagError = true;
    }
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.resetPassword(model)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => goToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setRMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Reset Password</h1>
                      <p className="mb-2">
                        Your new password must be different from old passwords.
                      </p>
                    </div>
                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <span className="input-icon1">
                            <img
                              src={Icon1}
                              alt="views"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type={passwordType}
                            className="form-control light-border pass-input"
                            id="newPassword"
                            placeholder="New Password*"
                            onChange={handelChange}
                            name="Password"
                            value={model.Password}
                          />
                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog2 ? "passVisible" : ""
                              }`}
                              onClick={newPassword}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">{errors.Password}</div>
                        <div className="form-group">
                          <span className="input-icon1">
                            <img
                              src={Icon1}
                              alt="views"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type={cpasswordType}
                            className="form-control light-border pass-input"
                            id="cnewPassword"
                            placeholder="Confirm New Password*"
                            onChange={handelChange}
                            name="CPassword"
                            value={model.CPassword}
                          />
                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog3 ? "passVisible" : ""
                              }`}
                              onClick={cnewPassword}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">{errors.CPassword}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className="btn btn-outline-primary blue-btn-bg "
                          >
                            {loader && (
                              <Spinner style={{ verticalAlign: "middle" }} />
                            )}
                            Save
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a href="#" className="h5 f-600 pl-2">
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <img src={Icon3} className="img-fluid logo-white" />
                  <h1>Welcome</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={goToSignin}
                    >
                      sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
