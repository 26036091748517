// Live
export const API_URL = "https://recordlinc.com/rlapi/api";
export const RecordLincURL = "https://recordlinc.com/";
// export const OCRURL = "http://refer.oneclickreferral.com/";
// export const VeridentURL = "http://verident.drdds.com/";
// export const ExcelPath = "https://recordlinc.com/RecordExcelFiles/";
export const IntigrationAutKey = "https://integrate.drdds.com/authKey/";

// localstorage constants
export const ProductName =
  localStorage.getItem("CompanyDetails") == null ||
  localStorage.getItem("CompanyDetails") === undefined
    ? ""
    : JSON.parse(localStorage.getItem("CompanyDetails"));

export const domain = window.location.hostname;
// export const domain = "scout.drdds.com";
// export const domain = "energize.drdds.com";

export const publicUrl = process.env.PUBLIC_URL; //live

export const url_link = window.location.origin; //live

export const HEADER = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
export const localStorageCompanyId = JSON.parse(localStorage.getItem("CompanyId"));
export const localStorageCompanyDetails = JSON.parse(localStorage.getItem("CompanyDetails"));
export const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
