import React from "react";
import { useHistory } from "react-router-dom";
// import street from "../assets/images/Street.svg";
// import city from "../assets/images/city.svg";
// import state from "../assets/images/State.svg";
// import country from '../assets/images/Country.svg';
// import ZipCode from "../assets/images/zip-code.svg";

export default function PracticeAddress() {
  let history = useHistory();
  const redirectToSignin = () => {
    history.push("/signin");
  };
  const redirectToSignup1 = () => {
    history.push("/signuppage1");
  };
  const redirectToSignup2 = () => {
    history.push("/signuppage2");
  };
  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-5 bg-green bg-green-1 d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {/* <!-- <img src="images/logo-white.png" className="img-fluid logo-white" /> --> */}
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign in
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 pl-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center ">
                    <div className="block-1">
                      <h1>
                        Sign up to{" "}
                        <span>
                          <b>Scout</b>
                        </span>
                      </h1>
                      <p className="mb-2">Enter your Practice Address</p>
                    </div>
                    <form>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="streetAddress"
                            placeholder="Street Address*"
                            defaultValue=""
                          />
                          <span className="input-icon1">
                            {/* <img
                              src={street}
                              alt="views"
                              className="img-fluid"
                            /> */}
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="city"
                            placeholder="City*"
                            defaultValue=""
                          />
                          <span className="input-icon1">
                            {/* <img src={city} alt="views" className="img-fluid" /> */}
                          </span>
                        </div>
                        <div className="form-group select-form-group">
                          {/* <input type="text" className="form-control light-border" id="city" placeholder="City*" defaultValue="" /> */}
                          <span className="input-icon1">
                            {/* <img
                              src={state}
                              alt="views"
                              className="img-fluid"
                            /> */}
                          </span>

                          <select className="form-control">
                            <option>Select State</option>
                            <option>Alabama </option>
                            <option>Alaska</option>
                            <option>American Samoa</option>
                            <option>California</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Montana</option>
                            <option>Nevada</option>
                            <option>New Jersey</option>
                            <option>New York</option>
                            <option>Ohio</option>
                            <option>Virginia</option>
                          </select>
                        </div>
                        <div className="form-group disabled">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="country"
                            placeholder="Country*"
                            defaultValue="United States"
                            disabled
                          />
                          <span className="input-icon1">
                            {/* <img src={country} alt="views" className="img-fluid" /> */}
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="zipCode"
                            placeholder="Zip Code*"
                          />
                          <span className="input-icon1">
                            {/* <img
                              src={ZipCode}
                              alt="views"
                              className="img-fluid"
                            /> */}
                          </span>
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="button"
                            className="btn btn-outline-primary   blue-btn-bg "
                            onClick={redirectToSignup2}
                          >
                            next
                          </button>
                        </div>
                      </div>
                      {/* <div className="account-btn-block">
                      <div className="btn-block">
                        <button type="button" className="btn btn-outline-primary   blue-btn-bg ">Sign up</button>
                      </div>
                    </div> */}
                      {/* <div className="forget-link  text-right link-purple signup-link">
                      <p>Already have Account ?<a href="#" className="h5 f-600 pl-2">Sign in</a></p>
                    </div> */}
                      <div className="account-btn-block text-center pt-2">
                        <a
                          onClick={redirectToSignup1}
                          className="back-button mr-auto pl-2"
                        >
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                          Back
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
