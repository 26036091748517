import React from "react";
import CustomeDatePicker from "./CustomeDatePicker";
import Select2 from "react-select2-wrapper";

const SubHeader = ({
  setChartType,
  dashboardSummaryModel,
  selectedChartType,
  executeSelectedOption,
  showDatePicker,
  handelDateChange,
  fromDateError,
  toDateError,
  handelApplyDateFilter,
}) => {
  return (
    <div id="sub-header">
      <div className="d-flex  py-4  justify-content-center  align-items-center  flex-wrap">
        <button
          type="button"
          onClick={() => setChartType(0)}
          className={`btn btn-sm blue-btn-bg green-btn ${
            dashboardSummaryModel.ChartType === 0 ? "active" : ""
          }`}
        >
          Day
        </button>
        <button
          type="button"
          onClick={() => setChartType(2)}
          className={`btn btn-sm blue-btn-bg green-btn ${
            dashboardSummaryModel.ChartType === 2 ? "active" : ""
          }`}
        >
          Current Week
        </button>
        <button
          type="button"
          onClick={() => setChartType(3)}
          className={`btn btn-sm blue-btn-bg green-btn ${
            dashboardSummaryModel.ChartType === 3 ? "active" : ""
          }`}
        >
          Current Month
        </button>
        <button
          type="button"
          onClick={() => setChartType(4)}
          className={`btn btn-sm blue-btn-bg green-btn ${
            dashboardSummaryModel.ChartType === 4 ? "active" : ""
          }`}
        >
          Current Year
        </button>
        <div className="style-select dayfilterSelect active">
          <Select2
            value={selectedChartType}
            data={[
              { text: "Custom Date", id: 8 },
              { text: "Yesterday", id: 1 },
              { text: "Last Week", id: 5 },
              { text: "Last Month", id: 6 },
              { text: "Last Year", id: 7 },
            ]}
            options={{
              placeholder: "Select",
            }}
            name="chartType"
            onChange={executeSelectedOption}
          />
        </div>
      </div>
      {showDatePicker && (
        <>
          <CustomeDatePicker
            handelDateChange={handelDateChange}
            fromDateError={fromDateError}
            toDateError={toDateError}
            handelApplyDateFilter={handelApplyDateFilter}
          />
        </>
      )}
    </div>
  );
};

export default SubHeader;
