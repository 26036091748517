import axios from "axios";
import { API_URL, HEADER } from "../config/appConstant";
let from = "Scout";
// main api class
class Api {
  // API for getting Dynamic company Details
  static getCompanyDetail = (url) => {
    return axios.get(API_URL + "/company/get?domain=" + url);
  };
  // API for get Company id
  static getCompanyId = (url) => {
    return axios.get(API_URL + "/Verident/CompanyId?strDomain=" + url);
  };
  // Log in API
  static logInPost = (payload) => {
    let UserModel = {
      Username: payload.Username,
      Password: payload.Password,
      CompanyId: payload.CompanyId,
    };
    const header = { ...HEADER.headers, Company_Id: payload.CompanyId };
    return axios.post(API_URL + "/Verident/Login", UserModel, header);
  };
  // Forgot password API
  static forgotPassword = (email) => {
    return axios.post(
      API_URL + "/Verident/ForgotPassowrd?Email=" + email + "&From=" + from,
      "",
      HEADER
    );
  };
  // Reset password API
  static resetPassword = (payload) => {
    let model = {
      Email: payload.Email,
      Password: payload.Password,
    };
    return axios.post(API_URL + "/Verident/ResetPassword", model, HEADER);
  };
  // Account activation API
  static accountActivation = (email) => {
    return axios.get(API_URL + "/Verident/AccountActivation?Email=" + email, "");
  };
}
export default Api;
