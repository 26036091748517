import React from "react";

export default function AppPageLoader() {
  return (
    <div
      className="loader-wrapper align-items-center justify-content-center"
      id="appLoadingMain"
      style={{ color: "red" }}
    >
      <div
        id="loading-bar-spinner"
        className="spinner"
        style={{ color: "red" }}
      >
        <div className="spinner-icon" style={{ color: "red" }}></div>
      </div>
    </div>
  );
}
