import React, { useLayoutEffect } from "react";

import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Api from "../src/API/login";
import Home from "./Home";
import Legal from "./Legal";
import SignIn from "./auth-pages/SignIn";
import SignUp from "./auth-pages/SignUp/index";

import ResetPassword from "./auth-pages/ResetPassword";
import ForgotPassword from "./auth-pages/ForgotPassword";
import PracticeAddress from "./auth-pages/PracticeAddress";
import ErrorPage from "./app-pages/ErrorPage";
import { ToastContainer } from "react-toastify";
import history from "./history";
import { domain, ProductName, publicUrl, url_link } from "./config/appConstant";

const App = () => {
  //Get domain name
  const [appLoaderState, setappLoaderState] = React.useState(true);
  const appThemeColor = (url, companyId) => {
    document.getElementById("favicon").href = `${url}/${companyId}/images/Favicon.png`;

    document.getElementById("custom-style").href = `${url}/${companyId}/css/style.css`;

    document.getElementById(
      "custom-responsive-style"
    ).href = `${url}/${companyId}/css/responsive.css`;

    document.getElementById(
      "custom-responsive-setting-style"
    ).href = `${url}/${companyId}/css/responsive-setting.css`;
  };

  useLayoutEffect(() => {
    if (
      JSON.parse(localStorage.getItem("CompanyId")) === null ||
      JSON.parse(localStorage.getItem("CompanyDetails")) === null ||
      (JSON.parse(localStorage.getItem("CompanyDetails")) &&
        domain !== JSON.parse(localStorage.getItem("CompanyDetails")).DomainName)
    ) {
      // call get company id API
      Api.getCompanyDetail(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", res.data.Result.CompanyId);
          localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
          document.title = res.data.Result.CompanyName;
          appThemeColor(url_link, res.data.Result.CompanyId);
          document.querySelector("#custom-style").onload = () => {
            setappLoaderState(false);
          };
          return res.data.CompanId;
        })
        .catch((error) => {
          setappLoaderState(false);
          return error;
        });
    } else {
      let companyId = localStorage.getItem("CompanyId");
      document.title = ProductName.CompanyName;
      appThemeColor(publicUrl, companyId);
      document.querySelector("#custom-style").onload = () => {
        setappLoaderState(false);
      };
    }
  }, []);

  // Check authentication
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") != null && localStorage.getItem("token").length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  // Check authentication
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == null || localStorage.getItem("token").length === 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );

  const Loading = () => (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          backgroundColor: "rgba(0, 0, 0, 0.37)",
          zIndex: "9999",
        }}
      >
        <div>
          <div
            style={{
              width: "70px",
              height: "70px",
              border: "solid 5px transparent",
              borderTopColor: "#00c8b1 !important",
              borderLeftColor: "#00c8b1 !important",
            }}
          >
            <h5>Loading...</h5>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {appLoaderState && <Loading />}
      {!appLoaderState && (
        <div className="App">
          <Router history={history}>
            <Switch>
              <PublicRoute path="/" component={SignIn} exact />
              <PublicRoute path="/signin" component={SignIn} exact />
              <PrivateRoute path="/dashboard" component={Home} exact />
              <Route path="/legal" component={Legal} exact />
              <PublicRoute path="/signup" component={SignUp} exact />
              <Route path="/practiceAddress" component={PracticeAddress} exact />
              <PublicRoute path="/resetpassword" component={ResetPassword} exact />
              <PublicRoute path="/forgotpassword" component={ForgotPassword} exact />

              <Route path="/errorPage" component={ErrorPage} exact />
              <Redirect to="/" />
            </Switch>
          </Router>
          <ToastContainer hideProgressBar />
        </div>
      )}
    </>
  );
};
export default App;
