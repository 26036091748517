import React from "react";
import AppSectionLoader from "../common-components/AppSectionLoader";
import Chart from "react-apexcharts";

const Patients = ({
  wdResModelNewPatients,
  detailPopUp,
  logos,
  openGoal,
  state2,
  getData,
  openComparison,
  state3,
  ByReferralSource,
  referralCountArray,
  referralCount,
  showWDNP,
  referralCountAmount,
}) => {
  return (
    <div id="chart-data" className="historical-doctor pb-0 circle-chart">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">New Patients</h6>
          </div>

          <div className="col-12 pb-3">
            <div className="summary-cards-container">
              {wdResModelNewPatients != null &&
                wdResModelNewPatients.length > 0 &&
                wdResModelNewPatients.map((add, i) => (
                  <div className={i > 0 ? "doctor-production pl-2" : "doctor-production"}>
                    <div className="white-card  p-0">
                      <div className="d-flex align-items-center justify-content-between p-3 border-bottom height-85 white-card-header">
                        <h6 className="blue-txt pb-0 cursor-unset" onClick={() => detailPopUp(add)}>
                          {add.Title}{" "}
                          <img
                            alt={add.Tooltip}
                            src={logos.Info}
                            className="info-icon"
                            title={add.Tooltip}
                          />
                        </h6>
                        <p className="black-txt">{add.Value}</p>
                      </div>
                      <div className="d-flex  align-items-center p-3">
                        <div className="flex-fill">
                          <p className="black-txt black-txt-small">
                            {add.WidgetCharts != null &&
                              add.WidgetCharts.length > 0 &&
                              add.WidgetCharts[0].Title}
                          </p>
                          {/* <div id="chart-gradi" data-toggle="modal" data-target="#drpro-goal"></div> */}
                          <Chart
                            onClick={() => openGoal(add)}
                            id="#chart-gradi"
                            // className=""
                            options={state2.options}
                            // series={state2.series}
                            series={
                              add.WidgetCharts.length > 0
                                ? getData(add.WidgetCharts[0].Value.slice(0, -1)).series
                                : getData(0).series
                            }
                            type="radialBar"
                            height={170}
                            width={120}
                          />
                        </div>
                        <div className="flex-fill">
                          <p className="black-txt black-txt-small">
                            {add.WidgetCharts != null &&
                              add.WidgetCharts.length > 1 &&
                              add.WidgetCharts[1].Title}
                          </p>
                          <Chart
                            onClick={() => openComparison(add)}
                            id="#chart-gradi1"
                            options={state3.options}
                            series={
                              add.WidgetCharts.length > 1
                                ? getData(add.WidgetCharts[1].Value.slice(0, -1)).series
                                : getData(0).series
                            }
                            type="radialBar"
                            height={170}
                            width={120}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="doctor-production pl-2">
                <div className="white-card  p-0">
                  <div className="d-flex align-items-center justify-content-between p-3 border-bottom height-85 white-card-header">
                    <h6 className="blue-txt pb-0 cursor-unset">By Referral Source</h6>
                  </div>

                  <div className="d-flex  align-items-center card-mt p-3">
                    <div className="flex-fill">
                      <Chart
                        id="new-5"
                        options={ByReferralSource(referralCountArray).options}
                        series={ByReferralSource(referralCountArray).series}
                        type="donut"
                        height={170}
                        width={160}
                      />
                      <span className="new-5-data">
                        {wdResModelNewPatients != null &&
                        wdResModelNewPatients.length > 0 &&
                        wdResModelNewPatients[2] &&
                        wdResModelNewPatients[2] ? (
                          wdResModelNewPatients[2].Value
                        ) : (
                          <h6>0</h6>
                        )}
                      </span>
                    </div>
                    <div className="flex-fill">
                      <ul className=" data-labels list-unstyled ">
                        {referralCount.length > 0 &&
                          referralCount.map((rec) => (
                            <li className="lightblue-label d-flex">
                              <div>
                                <i className="fa fa-circle" aria-hidden="true"></i>
                              </div>
                              {rec.FirstName + " " + rec.LastName} : {rec.ReferralCount}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="doctor-production pl-2">
                <div className="white-card  p-0">
                  <div className="d-flex align-items-center justify-content-between p-3 border-bottom height-85 white-card-header">
                    <h6 className="blue-txt pb-0 cursor-unset">Production By Referral Source</h6>
                  </div>

                  <div className="d-flex  align-items-center card-mt p-3">
                    <div className="flex-fill">
                      <Chart
                        id="new-7"
                        options={ByReferralSource(referralCountAmount).options}
                        series={ByReferralSource(referralCountAmount).series}
                        type="donut"
                        height={170}
                        width={160}
                      />
                      <span className="new-7-data">
                        {wdResModelNewPatients != null &&
                        wdResModelNewPatients.length > 0 &&
                        wdResModelNewPatients[2] &&
                        wdResModelNewPatients[2] ? (
                          wdResModelNewPatients[2].Value
                        ) : (
                          <h6>0</h6>
                        )}
                      </span>
                    </div>
                    <div className="flex-fill ">
                      <ul className=" data-labels list-unstyled ">
                        {referralCount.length > 0 &&
                          referralCount.map((rec) => (
                            <li className="lightblue-label d-flex">
                              <div>
                                <i className="fa fa-circle" aria-hidden="true"></i>
                              </div>
                              {rec.FirstName + " " + rec.LastName} : {rec.ReferralAmount}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={!showWDNP ? "d-none" : ""}>
              <AppSectionLoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patients;
