import React from "react";

const CustomeDatePicker = ({
  handelDateChange,
  fromDateError,
  toDateError,
  handelApplyDateFilter,
}) => {
  return (
    <div className="d-flex  py-4  justify-content-center  align-items-end border-top flex-wrap">
      <div className="filter-ctrl">
        <label>From Date</label>
        <input
          type="date"
          className="form-control date-select"
          name="fromDate"
          max={new Date().toISOString().slice(0, 10)}
          onChange={handelDateChange}
        />
      </div>
      <div className="err-message">{fromDateError}</div>
      <div className="filter-ctrl">
        <label>To Date</label>
        <input
          type="date"
          className="form-control date-select"
          name="toDate"
          onChange={handelDateChange}
          max={new Date().toISOString().slice(0, 10)}
        />
      </div>
      <div className="err-message">{toDateError}</div>
      <button
        type="button"
        className="btn btn-sm blue-btn-bg green-btn green-border "
        onClick={handelApplyDateFilter}
      >
        Apply
      </button>
    </div>
  );
};

export default CustomeDatePicker;
