import axios from "axios";
import { API_URL, HEADER } from "../config/appConstant";
// main api class
class Api {
  // Sign up API
  static signUp = (payload) => {
    return axios.post(API_URL + "/Verident/SignUp", payload, HEADER);
  };
  // Email check API
  static getCheckEmail = (email) => {
    return axios.get(API_URL + "/Verident/CheckEmailExists?Email=" + email, HEADER);
  };
  // Account activation API
  static accountActivation = (email) => {
    return axios.get(API_URL + "/Verident/AccountActivation?Email=" + email, HEADER);
  };
  // Get Specialities
  static getSpecialities = () => {
    return axios.get(API_URL + "/Verident/GetSpecialities?IsSignUP=True", HEADER);
  };
  // Get State
  static getState = (countryId) => {
    return axios.get(API_URL + "/State/Get/" + countryId, HEADER);
  };

  static getIntegrationList = () => {
    return axios.get(API_URL + "/Integrationlist", HEADER);
  };
}
export default Api;
