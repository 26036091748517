import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Api from "../API/dashboard";
import { RecordLincURL } from "../config/appConstant";
import { checkNumber } from "../config/commonFun";

const DashboardModal = (props) => {
  const [selectedWidget, setSelectedWidget] = useState({
    IsRowData: true,
    Module: 1,
    AccountId: "",
    UserId: "",
    ChartType: 3,
    StartDate: "",
    EndDate: "",
  });

  const [dashboardSummaryModel, setDashboardSummaryModel] = useState({
    AccountId: 0,
    UserId: 0,
    ChartType: 3,
    StartDate: "",
    EndDate: "",
  });

  const [errorMessage, setErrorMessage] = useState({ errorMessage: "" });

  const downloadExcelFile = () => {
    const data = {
      IsRowData: true,
      Module: selectedWidget.Module,
      AccountId: dashboardSummaryModel.AccountId,
      UserId: dashboardSummaryModel.UserId,
      ChartType: dashboardSummaryModel.ChartType,
      StartDate: dashboardSummaryModel.StartDate,
      EndDate: dashboardSummaryModel.EndDate,
    };
    props.setAppPageLoader(true);
    Api.downloadDashboardSummary(data)
      .then((res) => {
        window.location.href = RecordLincURL + "IntegrationExcelFiles\\" + res.data.Result;
        setErrorMessage({ errorMessage: "" });
        props.setAppPageLoader(false);
      })
      .catch((err) => {
        setErrorMessage({ errorMessage: "Record not found" });
        props.setAppPageLoader(false);
      });
  };

  useEffect(() => {
    if (props.selectedWidget != null) {
      setSelectedWidget(props.selectedWidget);
    }
  }, [props.selectedWidget]);

  useEffect(() => {
    setDashboardSummaryModel(props.dashboardSummaryModel);
  }, [props.dashboardSummaryModel]);

  useEffect(() => {
    setDashboardSummaryModel(props.dashboardSummaryModel);
    setSelectedWidget(props.selectedWidget);
    setErrorMessage({ errorMessage: "" });
  }, []);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            {selectedWidget.Title}
            {selectedWidget.IsRowData === true && checkNumber(selectedWidget.Value) > 0 && (
              <span className="m-0 p-0">
                <button className="btn m-0 p-0" onClick={downloadExcelFile}>
                  <i
                    title="Download Excel File"
                    className="fa fa-lg fa-file-excel-o mx-2 mt-0"
                    aria-hidden="true"
                  ></i>
                </button>
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        {errorMessage.errorMessage !== "" && (
          <div className="mx-auto text-danger">{errorMessage.errorMessage}</div>
        )}
        <Modal.Body className="app-model-body">
          <p> {selectedWidget.Tooltip}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardModal;
