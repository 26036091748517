import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { validNumberRegex } from "../config/regex";
import { validateForm } from "../config/commonFun";
import Api from "../API/dashboard";
function Goal(props) {
  const [loader, setLoader] = useState(false);
  const [goalModel, setGoalModel] = useState({
    DailyGoal: "0",
    WeeklyGoal: "0",
    MonthlyGoal: "0",
    YearlyGoal: "0",
  });
  const [goalModelErrors, setGoalModelErrors] = useState({
    DailyGoal: "",
    WeeklyGoal: "",
    MonthlyGoal: "",
    YearlyGoal: "",
  });

  const [postGoalModel, setPotGoalModel] = useState({
    Id: 0,
    AccountId: 0,
    DoctorId: 0,
    DailyGoal: 0,
    WeeklyGoal: 0,
    MonthlyGoal: 0,
    YearlyGoal: 0,
    WidgetId: 0,
    Remark: "",
    IsActive: true,
    ActionBy: 0,
  });

  useEffect(() => {
    let obj = { ...goalModel };
    let goal = props.selectedGoal != null && {
      ...props.selectedGoal,
    };
    if (
      goal != null &&
      goal.goalDetails != null &&
      goal.goalDetails.Goal != null &&
      goal.goalDetails.Goal.length > 0
    ) {
      let objData = { ...postGoalModel };
      objData.Id = goal.goalDetails.GoalId;
      objData.WidgetId = goal.widgetId;
      objData.AccountId = props.AccountId;
      objData.DoctorId = props.DoctorId;
      objData.ActionBy = props.DoctorId;
      setPotGoalModel(objData);
      if (goal.goalDetails.Goal.length > 0) {
        obj.DailyGoal =
          goal.goalDetails.Goal[0].Value > 0
            ? goal.goalDetails.Goal[0].Value
            : "";
      }
      if (goal.goalDetails.Goal.length > 1) {
        obj.WeeklyGoal =
          goal.goalDetails.Goal[1].Value > 0
            ? goal.goalDetails.Goal[1].Value
            : "";
      }
      if (goal.goalDetails.Goal.length > 2) {
        obj.MonthlyGoal =
          goal.goalDetails.Goal[2].Value > 0
            ? goal.goalDetails.Goal[2].Value
            : "";
      }
      if (goal.goalDetails.Goal.length > 3) {
        obj.YearlyGoal =
          goal.goalDetails.Goal[3].Value > 0
            ? goal.goalDetails.Goal[3].Value
            : "";
      }
    }
    setGoalModel(obj);
  }, [props.widgetId]);

  const handelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setGoalModel((goalModel) => ({
      ...goalModel,
      [name]: value,
    }));
    switch (name) {
      case "DailyGoal":
        errorValue =
          value.length > 0
            ? validNumberRegex.test(value)
              ? ""
              : "Please enter only number."
            : "";
        break;
      case "WeeklyGoal":
        errorValue =
          value.length > 0
            ? validNumberRegex.test(value)
              ? ""
              : "Please enter only number."
            : "";
        break;
      case "MonthlyGoal":
        errorValue =
          value.length > 0
            ? validNumberRegex.test(value)
              ? ""
              : "Please enter only number."
            : "";
        break;
      case "YearlyGoal":
        errorValue =
          value.length > 0
            ? validNumberRegex.test(value)
              ? ""
              : "Please enter only number."
            : "";
        break;
      default:
        break;
    }
    setGoalModelErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (
      goalModel.DailyGoal.length > 0 &&
      !validNumberRegex.test(goalModel.DailyGoal)
    ) {
      setGoalModelErrors((errors) => ({
        ...errors,
        DailyGoal: "Please enter only number.",
      }));
      flagError = true;
    }
    if (
      goalModel.YearlyGoal.length > 0 &&
      !validNumberRegex.test(goalModel.YearlyGoal)
    ) {
      setGoalModelErrors((errors) => ({
        ...errors,
        YearlyGoal: "Please enter only number.",
      }));
      flagError = true;
    }
    if (
      goalModel.WeeklyGoal.length > 0 &&
      !validNumberRegex.test(goalModel.WeeklyGoal)
    ) {
      setGoalModelErrors((errors) => ({
        ...errors,
        WeeklyGoal: "Please enter only number.",
      }));
      flagError = true;
    }
    if (
      goalModel.MonthlyGoal.length > 0 &&
      !validNumberRegex.test(goalModel.MonthlyGoal)
    ) {
      setGoalModelErrors((errors) => ({
        ...errors,
        MonthlyGoal: "Please enter only number.",
      }));
      flagError = true;
    }
    if (validateForm(goalModelErrors) && !flagError) {
      let obj = postGoalModel;
      obj.DailyGoal = goalModel.DailyGoal;
      obj.WeeklyGoal = goalModel.WeeklyGoal;
      obj.MonthlyGoal = goalModel.MonthlyGoal;
      obj.YearlyGoal = goalModel.YearlyGoal;
      obj.Remark = "";

      if (obj.DoctorId != null && obj.DoctorId > 0) {
        setLoader(true);
        Api.postGoal(postGoalModel)
          .then((res) => {
            setLoader(false);
            if (res.data.IsSuccess) {
              props.onHide();
              props.reload();
            }
          })
          .catch((error) => {
            setLoader(false);
          });
      }
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            {props.selectedGoal &&
              props.selectedGoal != null &&
              props.selectedGoal.Title.length > 0 &&
              props.selectedGoal.Title + " Goal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 ">
                <input
                  type="text"
                  className="form-control"
                  id="DailyGoal"
                  placeholder="Daily Goal"
                  name="DailyGoal"
                  value={goalModel.DailyGoal === "0" ? "" : goalModel.DailyGoal}
                  onChange={handelChange}
                />
              </div>
              <div className="err-message">{goalModelErrors.DailyGoal}</div>
              <div className=" col-12 ">
                <input
                  type="text"
                  className="form-control"
                  id="WeeklyGoal"
                  placeholder="Weekly Goal"
                  name="WeeklyGoal"
                  value={
                    goalModel.WeeklyGoal === "0" ? "" : goalModel.WeeklyGoal
                  }
                  onChange={handelChange}
                />
              </div>
              <div className="err-message">{goalModelErrors.WeeklyGoal}</div>
              <div className=" col-12 ">
                <input
                  type="text"
                  className="form-control"
                  id="MonthlyGoal"
                  placeholder="Monthly Goal"
                  name="MonthlyGoal"
                  value={
                    goalModel.MonthlyGoal === "0" ? "" : goalModel.MonthlyGoal
                  }
                  onChange={handelChange}
                />
              </div>
              <div className="err-message">{goalModelErrors.MonthlyGoal}</div>
              <div className=" col-12 ">
                <input
                  type="text"
                  className="form-control"
                  id="YearlyGoal"
                  placeholder="Yearly Goal"
                  name="YearlyGoal"
                  value={
                    goalModel.YearlyGoal === "0" ? "" : goalModel.YearlyGoal
                  }
                  onChange={handelChange}
                />
              </div>
              <div className="err-message">{goalModelErrors.YearlyGoal}</div>
            </div>
            {props.DoctorId > 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-primary blue-btn-bg "
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Goal;
