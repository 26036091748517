import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { goToSignin } from "../config/commonFun";
import Api from "../API/login";

import { validEmailRegex } from "../config/regex";

export default function ForgotPasswordPage() {
  // Email field
  const [email, setEmail] = useState("");
  // Email error
  const [emailError, setEmailError] = useState("");
  // Response message
  const [rMessage, setRMessage] = useState("");
  // Loader
  const [loader, setLoader] = useState(false);

  // form control change event
  const handelChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    setEmail(value);
    if (value.trim().length == 0) {
      setEmailError("Email is required.");
    } else {
      setEmailError(validEmailRegex.test(value) ? "" : "Enter valid email.");
    }
  };
  // submit from
  const handleSubmit = (event) => {
    setRMessage("");
    if (email.length == 0) {
      setEmailError("Email is required.");
    } else if (!validEmailRegex.test(email)) {
      setEmailError("Enter valid email.");
    } else {
      event.preventDefault();
      setLoader(true);
      // Call forgot password api
      Api.forgotPassword(email)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => goToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setEmailError(error.response.data.Message);
        });
    }
  };
  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Forgot Password</h1>
                      <p className="mb-2">
                        {" "}
                        You will get reset password link on your e-mail.
                      </p>
                    </div>
                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handleSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="email"
                            maxLength="100"
                            placeholder="Email Address*"
                            name="email"
                            value={email}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">
                            <i class="fa fa-envelope"></i>
                          </span>
                        </div>
                        <div className="err-message">{emailError}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className="btn btn-outline-primary blue-btn-bg"
                          >
                            {loader && (
                              <Spinner style={{ verticalAlign: "middle" }} />
                            )}
                            Send password reset email
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a
                              href="#"
                              onClick={goToSignin}
                              className="h5 f-600 pl-2"
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {/* <img src={Icon3} className="img-fluid logo-white" /> */}
                  <h1>Welcome</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={goToSignin}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
