import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { Spinner } from "reactstrap";
import useIntegrationStateList from "../_helperFunctions/IntegrationList";
import Api from "../../API/signup";
import { goToSignin } from "../../config/commonFun";
import { publicUrl } from "../../config/appConstant";
import AppPageLoader from "../../common-components/AppPageLoader";

const Step3 = ({ nextPage, postData, apiData, previousPage }) => {
  const { intList, intListError } = useIntegrationStateList();
  const [practiceErrorModel, setPracticeErrorModel] = useState({
    PracticeName: "",
    Speciality: "",
    OtherPraticeManagement: "",
    IntegrationId: "",
  });
  const [practiceModel, setPracticeModel] = useState({ ...postData, IntegrationId: 0 });
  const [resMessage, setResMessage] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [commonError, setCommonError] = useState("");

  const [loader, setLoader] = useState(false);

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((response) => {
        const specialityList = response.data.Result.map(({ Id, Description }) => ({
          id: Id,
          name: Description,
        }));
        setSpecialityOptions(specialityList);
      })
      .catch((error) => {
        setSpecialityOptions([]);
        setCommonError("There is some error in speciality option list");
      });
  };

  // =================handle events ===================

  const handleChange = (event, inputName) => {
    if (inputName === "Speciality") {
      setPracticeModel({ ...practiceModel, Speciality: event.map((value) => value.id) });
    } else {
      const { name, value } = event.target;
      setPracticeModel({ ...practiceModel, [name]: value });
    }
    let errorValue = "";
    switch (inputName) {
      case "PracticeName":
        if (event.target.value.trim().length === 0) errorValue = "Practice Name is required.";
        else errorValue = "";
        break;
      case "Speciality":
        if (event.id === 0) errorValue = "Speciality is required.";
        else errorValue = "";
        break;
      case "IntegrationId":
        if (event.id === 0) errorValue = "IntegrationId is required.";
        else errorValue = "";
        break;
      default:
        break;
    }
    setPracticeErrorModel((parcticeErrorModel) => ({
      ...parcticeErrorModel,
      [inputName]: errorValue,
    }));
  };

  const signUp = (event) => {
    event.preventDefault();
    let flagError = false;
    if (practiceModel.PracticeName.length === 0) {
      setPracticeErrorModel((practiceModel) => ({
        ...practiceModel,
        PracticeName: "Practice Name is required.",
      }));
      flagError = true;
    }
    if (practiceModel.Speciality.length === 0) {
      setPracticeErrorModel((practiceModel) => ({
        ...practiceModel,
        Speciality: "Speciality is required.",
      }));
      flagError = true;
    }
    if (practiceModel.IntegrationId === 0) {
      setPracticeErrorModel((practiceModel) => ({
        ...practiceModel,
        IntegrationId: "IntegrationId is required.",
      }));
      flagError = true;
    }

    if (practiceModel.IntegrationId === "-1" && practiceModel.OtherPraticeManagement.length === 0) {
      setPracticeErrorModel((practiceModel) => ({
        ...practiceModel,
        OtherPraticeManagement: "Other Practice Management is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      const userInfo = {
        ...postData,
        ...practiceModel,
      };
      setLoader(true);
      Api.signUp(userInfo)
        .then((res) => {
          if (res.data.IsSuccess) {
            setResMessage(
              "Please check your email for a confirmation email to login to your account."
            );
            toast.success(
              "Please check your email for a confirmation email to login to your account."
            );
            nextPage();
          }
        })
        .catch((error) => {
          setResMessage(error.response.data.Message);
        })
        .finally(() => setLoader(false));
    }
  };

  useEffect(() => {
    getSpecialities();
  }, []);
  return (
    <>
      {loader && <AppPageLoader className={"position-fixed"} />}
      <div className="inner-block text-center">
        <div className="block-1">
          <h1>
            Sign up to{" "}
            <span>
              <span>{apiData && <b>{apiData.CompanyName}</b>}</span>
            </span>
          </h1>
          <p className="mb-2">Enter your Practice information</p>
        </div>
        <div>
          {resMessage}

          {resMessage && practiceModel.OtherPraticeManagement && (
            <>
              <br />
              <span>
                {" "}
                At this time we do not support <b>{practiceModel.OtherPraticeManagement}</b> but we
                are always adding more and will let you know when it becomes available.
              </span>
            </>
          )}
        </div>
        <div className="text-danger">{commonError}</div>
        <form>
          <div className="content">
            <div className="form-group">
              <input
                type="text"
                className="form-control light-border"
                id="PracticeName"
                maxLength="50"
                placeholder="Practice Name*"
                value={practiceModel.PracticeName}
                name="PracticeName"
                onChange={(e) => handleChange(e, "PracticeName")}
              />
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/zip-code.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
            </div>
            <div className="err-message">{practiceErrorModel.PracticeName}</div>
            <div className="form-group has-value select-form-group">
              <Multiselect
                className="multi-select"
                options={specialityOptions} // Options to display in the dropdown
                showCheckbox={true}
                placeholder="Select Dental Specility"
                displayValue="name"
                closeOnSelect={false}
                onSelect={(selectedList) => handleChange(selectedList, "Speciality")}
              />
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/dentalSpeciality.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <span className="select-down-arrow">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </div>
            <div className="err-message">{practiceErrorModel.Speciality}</div>
            <div className="form-group select-form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/practiceManagementSystem.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <select
                className="form-control"
                name="IntegrationId"
                placeholder="Pratice management system"
                onChange={(e) => handleChange(e, "IntegrationId")}
                value={practiceModel.IntegrationId}
              >
                {intList.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="err-message">{practiceErrorModel.IntegrationId}</div>
            <div className="ml-2 p-0 text-danger">{intListError}</div>
            {practiceModel.IntegrationId === "-1" && (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control light-border"
                    id="Other Pratice Management"
                    placeholder="Other Pratice Management*"
                    name="OtherPraticeManagement"
                    value={practiceModel.OtherPraticeManagement}
                    onChange={(e) => handleChange(e, "OtherPraticeManagement")}
                  />
                  <span className="input-icon1">
                    {apiData && (
                      <img
                        src={`${publicUrl}/${apiData.CompanyId}/images/practiceManagementSystem.svg`}
                        alt="views"
                        className="img-fluid"
                      />
                    )}
                  </span>
                </div>
                <div className="err-message">{practiceErrorModel.OtherPraticeManagement}</div>
              </>
            )}
          </div>
          <div className="account-btn-block">
            <div className="btn-block">
              <button
                type="button"
                onClick={signUp}
                className="btn btn-outline-primary blue-btn-bg "
              >
                {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                Sign up
              </button>
            </div>
          </div>
          <div className="forget-link  text-right link-purple signup-link">
            <p>
              Already have Account ?
              <button onClick={goToSignin} className="h5 f-600 pl-2">
                Sign in
              </button>
            </p>
          </div>
          <div className="account-btn-block text-center pt-2">
            <Link onClick={previousPage} className="back-button mr-auto pl-2">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
              Back
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step3;
