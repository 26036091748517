import React, { useState, useEffect } from "react";
import { validateForm, gotoForgotPassword, gotoSignUp, gotoDashboard } from "../config/commonFun";
import { Spinner } from "reactstrap";

import Api from "../API/login";
import { domain, publicUrl } from "../config/appConstant";
import AppPageLoader from "../common-components/AppPageLoader";
import { Link } from "react-router-dom";

export default function SignInPage() {
  // Eye icon
  const [eyeTog2, seteyeTog2] = useState("false");

  // User object
  const [userModel, setUserModel] = useState({
    Username: "",
    Password: "",
    CompanyId: "",
  });

  // user error object
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });

  // Text box type for password
  const [textBoxType, setTextBoxType] = useState("password");

  // Error response message
  const [resMessage, setResMessage] = useState("");
  const [loader, setLoader] = useState(false);

  // form control change event
  const handelChange = (event) => {
    let { name, value } = event.target;

    let errorValue = "";
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));
    switch (name) {
      case "Username":
        if (value.length === 0) {
          errorValue = "Email or Username required.";
        }
        break;
      case "Password":
        if (value.length === 0) {
          errorValue = "Password is required.";
        }
        break;
      default:
        break;
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  // Change textbox type
  const changeTypeOfBox = () => {
    textBoxType === "password" ? setTextBoxType("text") : setTextBoxType("password");
    seteyeTog2(!eyeTog2);
  };

  // form submit event
  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (userModel.Username.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Username: "Email or Username required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Password: "Password is required.",
      }));
      flagError = true;
    }
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      const data = {
        ...userModel,
        CompanyId: localStorage.getItem("CompanyId"),
      };
      Api.logInPost(data)
        .then((res) => {
          setLoader(false);
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  const [apiLoader, setApiLoader] = useState(false);
  const [apiData, setApiData] = useState(null);
  // check user is already login or not
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      gotoDashboard();
    }
    if (
      localStorage.getItem("CompanyId") == null ||
      localStorage.getItem("CompanyDetails") === undefined ||
      (localStorage.getItem("CompanyDetails") &&
        domain !== JSON.parse(localStorage.getItem("CompanyDetails")).DomainName)
    ) {
      setApiLoader(false);
      Api.getCompanyDetail(domain)
        .then((res) => {
          setApiLoader(true);
          localStorage.setItem("CompanyId", res.data.Result.CompanyId);
          localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
          document.title = res.data.Result.CompanyName;
          setApiData(res.data.Result);
          return res.data.CompanyId;
        })
        .catch((error) => {
          setApiLoader(true);
        });
    } else {
      setApiLoader(true);
      let company_Details = JSON.parse(localStorage.getItem("CompanyDetails"));
      setApiData(company_Details);
    }
  }, []);

  return (
    <>
      {!apiLoader && <AppPageLoader />}
      {apiLoader && (
        <section className="common-section vh-100">
          <div className="container-fluid h-100">
            <div className="main-block align-items-center justify-content-center h-100 d-flex">
              <div className="login sub-block">
                <div className="row justify-content-center">
                  <div className="col-lg-7 pr-lg-0">
                    <div className="d-block">
                      <div className="inner-block text-center">
                        <div className="block-1">
                          <h1>
                            Sign in to{" "}
                            <span>
                              <b>{apiData && apiData.CompanyName}</b>
                            </span>
                          </h1>
                          <p>Welcome back! Enter your credentials to proceed </p>
                        </div>

                        <form onSubmit={handelSubmit}>
                          <div className="content">
                            {resMessage !== "" && (
                              <div className="err-message main-err-message">{resMessage}</div>
                            )}
                            <div className="form-group">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control light-border"
                                  id="username"
                                  placeholder="User Name / Email Address*"
                                  name="Username"
                                  value={userModel.Username}
                                  onChange={handelChange}
                                />
                                <span className="input-icon1">
                                  {apiData && (
                                    <img
                                      src={`${publicUrl}/${apiData.CompanyId}/images/envelope.svg`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  )}
                                </span>
                                {errors.Username.length > 0 && (
                                  <div className="err-message">{errors.Username}</div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <span className="input-icon1">
                                {apiData && (
                                  <img
                                    src={`${publicUrl}/${apiData.CompanyId}/images/padlock.svg`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </span>
                              <input
                                type={textBoxType}
                                className="form-control light-border pass-input"
                                id="exampleInputPassword1"
                                placeholder="Password*"
                                name="Password"
                                value={userModel.Password}
                                onChange={handelChange}
                              />
                              <span className="input-icon">
                                <i
                                  className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                                  onClick={changeTypeOfBox}
                                ></i>
                              </span>
                              {errors.Password.length > 0 && (
                                <div className="err-message">{errors.Password}</div>
                              )}
                            </div>
                            <div className="forget-link  link-purple">
                              <Link href="#" className="h5 f-600" onClick={gotoForgotPassword}>
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                          <div className="account-btn-block">
                            <div className="btn-block">
                              <button
                                type="submit"
                                className="btn btn-outline-primary   blue-btn-bg "
                              >
                                {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                                Sign In
                              </button>
                            </div>
                          </div>
                          <div className="forget-link  text-right link-purple signup-link">
                            <p>
                              Don't have Account ?
                              <Link href="#" className="h5 f-600 pl-2" onClick={gotoSignUp}>
                                Sign Up
                              </Link>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 bg-green d-flex">
                    <div className="justify-content-center align-self-center text-center">
                      {apiData && (
                        <img
                          src={`${publicUrl}/${apiData.CompanyId}/images/logo-white.svg`}
                          alt="Company Logo White"
                          className="img-fluid logo-white"
                        />
                      )}
                      <h1>Welcome</h1>
                      <p>Enter your personal information to create account</p>
                      <div className="btn-block">
                        <button
                          type="button"
                          className="btn btn-outline-primary blue-btn-bg "
                          onClick={gotoSignUp}
                        >
                          sign up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
