import React, { useEffect, useState } from "react";
import AppNavbar from "./common-components/AppNavbar";
import AppPageLoader from "./common-components/AppPageLoader";
import Productioncomparison from "./models/Productioncomparison";
import Api from "./API/dashboard";
import {
  localStorageCompanyId,
  localStorageUserData,
  ProductName,
  publicUrl,
} from "./config/appConstant";
import { Helmet } from "react-helmet";
import axios from "axios";
import Goal from "./models/Goal";
import SubHeader from "./DashboardSummary/SubHeader";
import DashboardSummarySection from "./DashboardSummary/DashboardSummarySection";
import DashboardModal from "./models/DashboardModal";
import { DoctorProduction } from "./DoctorProduction/DoctorProduction";
import Collection from "./Collection/Collection";
import Patients from "./Patients/Patients";
import CaseAcceptance from "./CaseAcceptance/CaseAcceptance";
import Hygiene from "./Hygiene/Hygiene";

const Dashboard_historical = () => {
  const companyId = localStorageCompanyId;

  const [theme, setTheme] = useState({
    ThemeColor: "",
    chartColor: { Doctor: "", Hygiene: "" },
  });

  const [logos, setLogos] = useState({
    Card1: "",
    Card2: "",
    Card3: "",
    Card4: "",
    Info: "",
  });

  const [provider, setProvider] = useState({
    DoctorId: 0,
    Salutation: "",
    FirstName: "",
    LastName: "",
    FullName: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [dashboardSummaryModel, setDashboardSummaryModel] = useState({
    AccountId: 0,
    UserId: 0,
    ChartType: 3,
    StartDate: "",
    EndDate: "",
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  const dummySummaryData = {
    DoctorProduction: {
      Value: "$0",
      ImageURL: "",
      Title: "Doctor Production",
      ToolTip: "Doctor Production",
      IsRowData: false,
      Module: 1,
      OrderBy: 1,
      SectionId: 1,
    },
    Collections: {
      Value: "$0",
      ImageURL: "",
      Title: "Collections",
      ToolTip: "Collections",
      IsRowData: false,
      Module: 2,
      OrderBy: 2,
      SectionId: 2,
    },
    Procedures: {
      Value: "0",
      ImageURL: "",
      Title: "Procedures",
      ToolTip: "Procedures",
      IsRowData: false,
      Module: 3,
      OrderBy: 3,
      SectionId: 3,
    },
    NewPatients: {
      ImageURL: "",
      IsRowData: false,
      Title: "Hygiene",
      ToolTip: "Hygiene",
      Value: "0",
      OrderBy: 4,
      SectionId: 4,
      Module: 4,
    },
    ActivePatientsWidget: {
      ImageURL: "",
      IsRowData: false,
      Title: "Active Patients",
      ToolTip: "Active Patients",
      Value: "0",
      OrderBy: 5,
      SectionId: 5,
      Module: 5,
    },
    InActivePatientsWidget: {
      ImageURL: "",
      IsRowData: false,
      Title: "Inactive Patients",
      ToolTip: "Inactive Patients",
      Value: "0",
      OrderBy: 6,
      SectionId: 6,
      Module: 6,
    },
    AccountReceivable: {
      ImageURL: "",
      IsRowData: false,
      Title: "Accounts Receivable",
      ToolTip: "Accounts Receivable",
      Value: "0",
      OrderBy: 7,
      SectionId: 7,
      Module: 7,
    },
    Hygiene: {
      Value: "0",
      ImageURL: "",
      Title: "Hygiene",
      ToolTip: "Hygiene",
      IsRowData: false,
      Module: 8,
      OrderBy: 8,
      SectionId: 8,
    },
  };
  const [dashboardSummaryResModel, setDashboardSummaryResModel] = useState(dummySummaryData);

  const [referralCount, setReferralCount] = useState([
    {
      FirstName: "",
      LastName: "",
      AccountId: 0,
      UserId: 0,
      ReferralCount: 0,
      ReferralAmount: "",
    },
  ]);

  const [showWDDoctorProduction, setShowWDDoctorProduction] = useState(true);
  const [showWDColl, setShowWDColl] = useState(true);
  const [showWDNP, setShowWDNP] = useState(true);
  const [showWDCA, setShowWDCA] = useState(true);
  const [showWDHygiene, setShowWDHygiene] = useState(true);

  //Doctor Production
  const [wdResModelDoctorProduction, setWDResModelDoctorProduction] = useState([
    {
      Id: 0,
      Title: "",
      Tooltip: "",
      Value: "",
      OrderBy: 0,
      WidgetCharts: {},
    },
  ]);
  //Collections
  const [wdResModelCollections, setWDResModelCollections] = useState([
    {
      Id: 0,
      Title: "",
      Tooltip: "",
      Value: "",
      OrderBy: 0,
      WidgetCharts: {},
    },
  ]);
  //New Patients
  const [wdResModelNewPatients, setWDResModelNewPatients] = useState([
    {
      Id: 0,
      Title: "",
      Tooltip: "",
      Value: "",
      OrderBy: 0,
      WidgetCharts: {},
    },
  ]);
  // Case Acceptance
  const [wdResModelCaseAcceptance, setWDResModelCaseAcceptance] = useState([
    {
      Id: 0,
      Title: "",
      Tooltip: "",
      Value: "",
      OrderBy: 0,
      WidgetCharts: {},
    },
  ]);
  // Hygiene
  const [wdResModelHygiene, setWDResModelHygiene] = useState([
    {
      Id: 0,
      Title: "",
      Tooltip: "",
      Value: "",
      OrderBy: 0,
      WidgetCharts: {},
    },
  ]);

  const [selectedGoal, setSelectedGoal] = useState({
    Id: 0,
    Title: "",
    Tooltip: "",
    Value: "",
    OrderBy: 0,
    WidgetCharts: {},
  });

  const [dashboardModal, setDashboardModal] = React.useState(false);
  const [appPageLoader, setAppPageLoader] = useState(false);

  const [ProductioncomparisonShow, SetProductioncomparison] = React.useState(false);
  const [showGoal, setShowGoal] = React.useState(false);
  const [selectedChartType, setSelectedChartType] = useState(3);
  const [month, setMonth] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [hygienes, setHygienes] = useState([]);

  const [referralCountArray, setreferralCountArray] = useState([]);
  const [referralCountAmount, setreferralCountAmount] = useState([]);

  const [selectedWidget, setSelectedWidget] = useState({
    Title: "",
    ToolTip: "",
    IsRowData: false,
    Value: "$0",
    ImageUrl: "",
    Module: 1,
  });

  const [showSectionLoading, setShowSectionLoading] = useState(true);
  const [showSectionLoadingChart, setShowSectionLoadingChart] = useState(true);
  const [fromDate, setFromDate] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDate, setToDate] = useState(false);
  const [toDateError, setToDateError] = useState(false);

  const [widgetId, setWidgetId] = useState(0);

  const setDammyData = () => {
    setWDResModelDoctorProduction([
      {
        Id: 1,
        Title: "Doctor Production",
        Tooltip: "Doctor Production",
        Value: "$0.00",
        OrderBy: 1,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "11000.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 2,
        Title: "Production Per New Patient",
        Tooltip: "Production Per New Patient",
        Value: "$0.00",
        OrderBy: 2,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 3,
        Title: "Production Per Hygiene Exam",
        Tooltip: "Production Per Hygiene Exam",
        Value: "$0.00",
        OrderBy: 3,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 4,
        Title: "Same Day Production",
        Tooltip: "Same Day Production",
        Value: "$0.00",
        OrderBy: 4,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
    ]);
    setWDResModelCollections([
      {
        Id: 5,
        Title: "Collections",
        Tooltip: "Collections",
        Value: "$0.00",
        OrderBy: 5,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 6,
        Title: "Collections %",
        Tooltip: "Collections %",
        Value: "0.00%",
        OrderBy: 6,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00%",
              },
              {
                Title: "2nd",
                Value: "0.00%",
              },
              {
                Title: "3rd",
                Value: "0.00%",
              },
              {
                Title: "4th",
                Value: "0.00%",
              },
              {
                Title: "5th",
                Value: "0.00%",
              },
            ],
          },
        ],
      },
      {
        Id: 7,
        Title: "Insurance Collections\r\n",
        Tooltip: "Insurance Collections\r\n",
        Value: "$0.00",
        OrderBy: 7,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 8,
        Title: "Patient Collections\r\n",
        Tooltip: "Patient Collections\r\n",
        Value: "$0.00",
        OrderBy: 8,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
    ]);
    setWDResModelNewPatients([
      {
        Id: 9,
        Title: "New Patients",
        Tooltip: "New Patients",
        Value: "0.00",
        OrderBy: 9,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00",
              },
              {
                Title: "2nd",
                Value: "0.00",
              },
              {
                Title: "3rd",
                Value: "0.00",
              },
              {
                Title: "4th",
                Value: "0.00",
              },
              {
                Title: "5th",
                Value: "0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 10,
        Title: "Production Per New Patient",
        Tooltip: "Production Per New Patient",
        Value: "$0.00",
        OrderBy: 10,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
    ]);
    setWDResModelCaseAcceptance([
      {
        Id: 13,
        Title: "Case Acceptance",
        Tooltip: "Case Acceptance",
        Value: "0.00%",
        OrderBy: 13,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "0",
                Value: "",
              },
              {
                Title: "1st",
                Value: "",
              },
              {
                Title: "2nd",
                Value: "",
              },
              {
                Title: "3rd",
                Value: "",
              },
              {
                Title: "4th",
                Value: "",
              },
              {
                Title: "5th",
                Value: "",
              },
            ],
          },
        ],
      },
      {
        Id: 14,
        Title: "Diagnosis",
        Tooltip: "Diagnosis",
        Value: "0.00%",
        OrderBy: 14,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00%",
              },
              {
                Title: "2nd",
                Value: "0.00%",
              },
              {
                Title: "3rd",
                Value: "0.00%",
              },
              {
                Title: "4th",
                Value: "0.00%",
              },
              {
                Title: "5th",
                Value: "0.00%",
              },
            ],
          },
        ],
      },
      {
        Id: 15,
        Title: "Patient Acceptance",
        Tooltip: "Patient Acceptance",
        Value: "0.00%",
        OrderBy: 15,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "0",
                Value: "",
              },
              {
                Title: "1st",
                Value: "",
              },
              {
                Title: "2nd",
                Value: "",
              },
              {
                Title: "3rd",
                Value: "",
              },
              {
                Title: "4th",
                Value: "",
              },
              {
                Title: "5th",
                Value: "",
              },
            ],
          },
        ],
      },
      {
        Id: 16,
        Title: "Avg. Treatment Presented",
        Tooltip: "Avg. Treatment Presented",
        Value: "$0.00",
        OrderBy: 16,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
    ]);
    setWDResModelHygiene([
      {
        Id: 17,
        Title: "Hygiene Production",
        Tooltip: "Hygiene Production",
        Value: "$0.00",
        OrderBy: 17,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "$0.00",
              },
              {
                Title: "2nd",
                Value: "$0.00",
              },
              {
                Title: "3rd",
                Value: "$0.00",
              },
              {
                Title: "4th",
                Value: "$0.00",
              },
              {
                Title: "5th",
                Value: "$0.00",
              },
            ],
          },
        ],
      },
      {
        Id: 18,
        Title: "Hygiene Pre-Appointment",
        Tooltip: "Hygiene Pre-Appointment",
        Value: "0.00%",
        OrderBy: 18,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00%",
              },
              {
                Title: "2nd",
                Value: "0.00%",
              },
              {
                Title: "3rd",
                Value: "0.00%",
              },
              {
                Title: "4th",
                Value: "0.00%",
              },
              {
                Title: "5th",
                Value: "0.00%",
              },
            ],
          },
        ],
      },
      {
        Id: 19,
        Title: "Perio Production",
        Tooltip: "Perio Production",
        Value: "0.00%",
        OrderBy: 19,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00%",
              },
              {
                Title: "2nd",
                Value: "0.00%",
              },
              {
                Title: "3rd",
                Value: "0.00%",
              },
              {
                Title: "4th",
                Value: "0.00%",
              },
              {
                Title: "5th",
                Value: "0.00%",
              },
            ],
          },
        ],
      },
      {
        Id: 20,
        Title: "Fluoride",
        Tooltip: "Fluoride",
        Value: "0.00%",
        OrderBy: 20,
        WidgetCharts: [
          {
            Title: "Goal",
            Tooltip: "",
            GoalId: 0,
            Value: "0.00%",
            Goal: [
              {
                Title: "Daily",
                Value: "0.00",
              },
              {
                Title: "Weekly",
                Value: "0.00",
              },
              {
                Title: "Monthly",
                Value: "0.00",
              },
              {
                Title: "Yearly",
                Value: "0.00",
              },
            ],
          },
          {
            Title: "Cross-User Comparison",
            Tooltip: "",
            GoalId: 0,
            Value: "0%",
            Goal: [
              {
                Title: "1st",
                Value: "0.00%",
              },
              {
                Title: "2nd",
                Value: "0.00%",
              },
              {
                Title: "3rd",
                Value: "0.00%",
              },
              {
                Title: "4th",
                Value: "0.00%",
              },
              {
                Title: "5th",
                Value: "0.00%",
              },
            ],
          },
        ],
      },
    ]);
  };

  const setUserId = (id) => {
    let obj = { ...dashboardSummaryModel };
    obj.AccountId = user.AccountId;
    obj.UserId = id === 0 ? null : id;
    setDashboardSummaryModel(obj);
    getDashboardSummary(obj);
    getProductionChart(obj);
    getReferralCount(obj);
  };

  const getDashboardSummary = (model) => {
    if (model.AccountId > 0) {
      setShowSectionLoading(true);
      setShowWDDoctorProduction(true);
      setShowWDColl(true);
      setShowWDNP(true);
      setShowWDCA(true);
      setShowWDHygiene(true);
      const newData = { ...dummySummaryData };
      Api.getDashboardSummary(model)
        .then((res) => {
          for (let index = 0; index < res.data.Result.length; index++) {
            const item = res.data.Result[index];

            if (item.Value) {
              item.Value = item.Value.split(".")[0];
            }
            switch (item.SectionId) {
              case 1:
                newData.DoctorProduction = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 1,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 2:
                newData.Collections = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 2,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 3:
                newData.NewPatients = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 4,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 5:
                newData.Hygiene = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 8,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 6:
                newData.Procedures = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 3,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 7:
                newData.AccountReceivable = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 7,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;

              case 32:
                newData.ActivePatientsWidget = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 5,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              case 33:
                newData.InActivePatientsWidget = {
                  Value: item.Value,
                  ImageUrl: item.ImageURL,
                  Title: item.Title,
                  Tooltip: item.ToolTip,
                  IsRowData: item.IsRowData,
                  Module: 6,
                  SectionId: item.SectionId,
                  OrderBy: item.OrderBy,
                };
                break;
              default:
                break;
            }
          }
          getWidgets(model);
          setShowSectionLoading(false);
          setDashboardSummaryResModel(newData);
        })
        .catch((error) => {
          setShowSectionLoading(false);
          getWidgets(model);
          setDashboardSummaryResModel(newData);
        });
    }
  };

  const getProductionChart = (model) => {
    if (model.AccountId > 0) {
      setShowSectionLoadingChart(true);
      Api.getProductionChart(model)
        .then((res) => {
          setShowSectionLoadingChart(false);
          let result = res.data.Result;
          let cate = [];
          let doctor = [];
          let hygienes = [];
          for (let i = 0; i < result.length; i++) {
            cate.push(result[i].LabelName);
            doctor.push(result[i].Value[0].RoundValue);
            hygienes.push(result[i].Value[1].RoundValue);
          }
          setMonth(cate);
          setDoctor(doctor);
          setHygienes(hygienes);
        })
        .catch((error) => {
          setShowSectionLoadingChart(false);
        });
    }
  };

  const getReferralCount = (model) => {
    if (model.AccountId > 0) {
      Api.getReferralCount(model)
        .then((res) => {
          setReferralCount(res.data.Result);
          if (res.data.Result.length > 0) {
            let arraCount = [];
            let arraAmount = [];
            for (let i = 0; i < res.data.Result.length; i++) {
              arraCount.push(res.data.Result[i].ReferralCount);
              arraAmount.push(parseInt(res.data.Result[i].ReferralAmount));
            }
            setreferralCountArray(arraCount);
            setreferralCountAmount(arraAmount);
          }
        })
        .catch((error) => {});
    }
  };

  const getWidgets = (model) => {
    getWidgetsDetails(model, 1);
  };

  const detailPopUp = (widgetData) => {
    setDashboardModal(!dashboardModal);
    setSelectedWidget(widgetData);
  };

  const getWidgetsDetails = (model, sectionId) => {
    if (model.AccountId > 0) {
      Api.getWidgetsDetails(model, sectionId)
        .then((res) => {
          switch (sectionId) {
            case 1:
              setShowWDDoctorProduction(false);
              setWDResModelDoctorProduction(res.data.Result);
              break;
            case 2:
              setShowWDColl(false);
              setWDResModelCollections(res.data.Result);
              break;
            case 3:
              setShowWDNP(false);
              setWDResModelNewPatients(res.data.Result);
              break;
            case 4:
              setShowWDCA(false);
              setWDResModelCaseAcceptance(res.data.Result);
              break;
            case 5:
              setShowWDHygiene(false);
              setWDResModelHygiene(res.data.Result);
              break;
            default:
              break;
          }

          let secId = parseInt(sectionId) + 1;
          if (secId <= 5) {
            getWidgetsDetails(model, secId);
          }
        })
        .catch((error) => {});
    }
  };

  const executeSelectedOption = (event) => {
    event.preventDefault();
    if (event.target.value != null && event.target.value != "") {
      setChartType(event.target.value);
    }
  };
  const setChartType = (id) => {
    let obj = { ...dashboardSummaryModel };
    setSelectedChartType(id);
    obj.ChartType = id === "8" ? null : id;
    obj.AccountId = user.AccountId;
    obj.UserId = obj.UserId === 0 ? null : obj.UserId;
    if (id !== "8") {
      getDashboardSummary(obj);
      getProductionChart(obj);
      getReferralCount(obj);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
    setDashboardSummaryModel(obj);
  };

  const getProvider = (AccountId) => {
    if (AccountId > 0) {
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      setDashboardSummaryModel(obj);
      Api.getProvider(AccountId)
        .then((res) => {
          setProvider(res.data.Result);
          getDashboardSummary(obj);
          getProductionChart(obj);
          getReferralCount(obj);
        })
        .catch((error) => {
          getDashboardSummary(obj);
          getProductionChart(obj);
          getReferralCount(obj);
        });
    }
  };

  const openComparison = (Object) => {
    SetProductioncomparison(!ProductioncomparisonShow);
    setSelectedGoal(Object);
  };

  const openGoal = (Object) => {
    let obj = selectedGoal;
    setWidgetId(Object.id);
    obj.widgetId = Object.id;
    obj.Title = Object.Title;
    obj.goalDetails = Object.goalDetails;
    setSelectedGoal(obj);
    setShowGoal(!showGoal);
    setSelectedGoal(Object);
  };

  const getProChart = (cat, docData, HyData, title) => {
    return {
      series: [
        {
          name: "Doctor",
          data: docData,
        },
        {
          name: "Hygiene",
          data: HyData,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 345,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: [theme.chartColor.Doctor, theme.chartColor.Hygiene],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },

        xaxis: {
          categories: cat,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
          title: {
            text: title,
          },
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: "Production ($1000)",
          },
          axisBorder: {
            show: true,
            color: " #c8c8c8",
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          enabled: true,
          marker: {
            show: false,
          },

          y: {
            show: false,
          },
          x: {
            show: false,
          },
          style: {
            fontSize: "12px",
          },
        },
        fill: {
          opacity: 1,
          colors: [theme.chartColor.Doctor, theme.chartColor.Hygiene],
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetX: 40,
        },
      },
    };
  };

  const getData = (per) => {
    return {
      series: [per],
      options: {
        chart: {
          height: 200,
          width: 150,
          type: "radialBar",
          fill: {
            colors: [
              function ({ value }) {
                if (value < 55) {
                  return "#7E36AF";
                } else if (value >= 55 && value < 80) {
                  return "#164666";
                } else {
                  return "#D9534F";
                }
              },
            ],
          },
        },

        plotOptions: {
          radialBar: {
            hollow: {
              size: "55%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: "18px",
                show: true,
                offsetY: 5,
              },
            },
            track: {
              background: theme.ThemeColor,
              opacity: 0.2,
            },
          },
        },
        fill: {
          opacity: 1.5,
          colors: [theme.ThemeColor],
        },
        stroke: {
          lineCap: "round",
        },
      },
    };
  };

  const state2 = {
    series: [50],
    options: {
      chart: {
        height: 200,
        width: 150,
        type: "radialBar",
        fill: {
          colors: [
            function ({ value }) {
              if (value < 55) {
                return "#7E36AF";
              } else if (value >= 55 && value < 80) {
                return "#164666";
              } else {
                return "#D9534F";
              }
            },
          ],
        },
      },

      plotOptions: {
        radialBar: {
          hollow: {
            size: "55%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "18px",
              show: true,
              offsetY: 5,
            },
          },
          track: {
            background: theme.ThemeColor,
            opacity: 0.2,
          },
        },
      },
      fill: {
        opacity: 1.5,
        colors: [theme.ThemeColor],
      },
      stroke: {
        lineCap: "round",
      },
    },
  };

  const state3 = {
    series: [45],
    options: {
      chart: {
        height: 200,
        width: 150,
        type: "radialBar",
      },

      plotOptions: {
        radialBar: {
          hollow: {
            size: "55%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "18px",
              show: true,
              offsetY: 5,
            },
          },
          track: {
            background: "#ff9100",
            opacity: 0.2,
          },
        },
      },
      fill: {
        opacity: 1.5,
        colors: ["#ff9100"],
      },
      stroke: {
        lineCap: "round",
      },
    },
  };

  const ByReferralSource = (data) => {
    return {
      cutoutPercentage: 40,
      series: data,
      options: {
        chart: {
          type: "donut",
          height: 100,
          width: 100,
        },
        colors: ["#ff0000", "#ff9100", "#01458c", "#00aeef"],
        labels: ["New Patients", "Existing Patients "],
        legend: {
          show: false,
          position: "right",
          itemMargin: {
            horizontal: 2,
          },
        },

        hollow: {
          size: "70%",
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },

        responsive: [
          {
            breakpoint: 1600,
            options: {
              chart: {
                height: 160,
                width: 130,
              },
              stroke: {
                width: 0,
              },
            },
          },
        ],
      },
    };
  };

  const handelDateChange = (event) => {
    event.preventDefault();
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setFromDateError("");
    setToDateError("");
    switch (name) {
      case "fromDate":
        if (value.length === 0) {
          errorValue = "From Date is required.";
        } else if (toDate !== "" && new Date(value) > new Date(toDate)) {
          errorValue = "Please select less date then To Date";
        } else {
          errorValue = "";
        }
        setFromDate(value);
        setFromDateError(errorValue);
        break;
      case "toDate":
        if (value.length === 0) {
          errorValue = "To Date is required.";
        } else if (fromDate !== "" && new Date(value) < new Date(fromDate)) {
          errorValue = "Please select greater date then From Date";
        } else {
          errorValue = "";
        }
        setToDate(value);
        setToDateError(errorValue);
        break;
      default:
        break;
    }
  };

  const handelApplyDateFilter = (event) => {
    event.preventDefault();

    let obj = { ...dashboardSummaryModel };
    obj.ChartType = null;
    obj.StartDate = fromDate;
    obj.EndDate = toDate;
    obj.AccountId = user.AccountId;
    setDashboardSummaryModel(obj);

    if (fromDateError.length === 0 && toDateError.length === 0) {
      getDashboardSummary(obj);
      getProductionChart(obj);
      getReferralCount(obj);
    } else {
      if (fromDateError.length === 0) {
        setFromDateError("From Date is required.");
      } else if (toDate !== "" && new Date(fromDateError) > new Date(toDate)) {
        setFromDateError("Please select less date then To Date");
      } else {
        setFromDateError("");
      }
      if (toDate.length === 0) {
        setToDateError("To Date is required.");
      } else if (fromDate !== "" && new Date(toDate) < new Date(fromDate)) {
        setToDateError("Please select greater date then From Date.");
      } else {
        setToDateError("");
      }
    }
  };

  useEffect(() => {
    setDammyData();
    setUser(localStorageUserData);
    getProvider(user.AccountId);
  }, [user.AccountId]);

  useEffect(() => {
    let timer1 = setTimeout(() => setAppPageLoader(null), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${publicUrl}/${companyId}/js/themeConstants.json`)
      .then((res) => {
        setTheme({
          ThemeColor: res.data.ThemeColor,
          chartColor: {
            Doctor: res.data.ChartColor.Doctor,
            Hygiene: res.data.ChartColor.Hygiene,
          },
        });
      })
      .catch((err) => {
        setTheme({
          ThemeColor: "#21ce99",
          chartColor: {
            Doctor: "#898d90",
            Hygiene: "#21ce9a",
          },
        });
      });
    const card1 = require(`./assets/images/bar-chart.png`);
    const card2 = require(`./assets/images/totalcollection.svg`);
    const card3 = require(`./assets/images/procedure.svg`);
    const card4 = require(`./assets/images/new-patients.svg`);
    const info = require(`./assets/images/information.svg`);
    setLogos({
      Card1: card1,
      Card2: card2,
      Card3: card3,
      Card4: card4,
      Info: info,
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{ProductName && ProductName.CompanyName}</title>
      </Helmet>
      <AppNavbar provider={provider} setUserId={setUserId} selectedChartType={selectedChartType} />
      <SubHeader
        setChartType={(val) => setChartType(val)}
        dashboardSummaryModel={dashboardSummaryModel}
        selectedChartType={selectedChartType}
        executeSelectedOption={executeSelectedOption}
        showDatePicker={showDatePicker}
        handelDateChange={handelDateChange}
        fromDateError={fromDateError}
        toDateError={toDateError}
        handelApplyDateFilter={handelApplyDateFilter}
      />
      <DashboardSummarySection
        detailPopUp={detailPopUp}
        dashboardSummaryResModel={dashboardSummaryResModel}
        showSectionLoading={showSectionLoading}
        showSectionLoadingChart={showSectionLoadingChart}
        month={month}
        doctor={doctor}
        hygienes={hygienes}
        getProChart={getProChart}
        theme={theme}
        logos={logos}
      />
      <DoctorProduction
        wdResModelDoctorProduction={wdResModelDoctorProduction}
        showWDDoctorProduction={showWDDoctorProduction}
        detailPopUp={detailPopUp}
        logos={logos}
        openGoal={openGoal}
        openComparison={openComparison}
        getData={getData}
        state2={state2}
        state3={state3}
      />
      <Collection
        wdResModelCollections={wdResModelCollections}
        detailPopUp={detailPopUp}
        showWDColl={showWDColl}
        logos={logos}
        openGoal={openGoal}
        openComparison={openComparison}
        getData={getData}
        state2={state2}
        state3={state3}
      />
      <Patients
        wdResModelNewPatients={wdResModelNewPatients}
        detailPopUp={detailPopUp}
        logos={logos}
        openGoal={openGoal}
        state2={state2}
        getData={getData}
        openComparison={openComparison}
        state3={state3}
        ByReferralSource={ByReferralSource}
        referralCountArray={referralCountArray}
        referralCount={referralCount}
        showWDNP={showWDNP}
        referralCountAmount={referralCountAmount}
      />
      <CaseAcceptance
        wdResModelCaseAcceptance={wdResModelCaseAcceptance}
        detailPopUp={detailPopUp}
        logos={logos}
        openGoal={openGoal}
        state2={state2}
        openComparison={openComparison}
        state3={state3}
        getData={getData}
        showWDCA={showWDCA}
      />
      <Hygiene
        wdResModelHygiene={wdResModelHygiene}
        detailPopUp={detailPopUp}
        logos={logos}
        openGoal={openGoal}
        state2={state2}
        getData={getData}
        openComparison={openComparison}
        state3={state3}
        showWDHygiene={showWDHygiene}
      />
      <Goal
        show={showGoal}
        AccountId={dashboardSummaryModel.AccountId}
        DoctorId={dashboardSummaryModel.UserId}
        selectedGoal={selectedGoal}
        widgetId={widgetId}
        onHide={() => setShowGoal(false)}
      />
      <DashboardModal
        appPageLoader={appPageLoader}
        setAppPageLoader={(pageLoaderValue) => setAppPageLoader(pageLoaderValue)}
        show={dashboardModal}
        selectedWidget={selectedWidget}
        dashboardSummaryModel={dashboardSummaryModel}
        onHide={() => setDashboardModal(false)}
      />
      <Productioncomparison
        show={ProductioncomparisonShow}
        selectedGoal={selectedGoal}
        onHide={() => SetProductioncomparison(false)}
      />
      {appPageLoader && <AppPageLoader />}
    </div>
  );
};

export default Dashboard_historical;
