import React from "react";
import AppSectionLoader from "../common-components/AppSectionLoader";
import Chart from "react-apexcharts";

const CaseAcceptance = ({
  wdResModelCaseAcceptance,
  detailPopUp,
  logos,
  openGoal,
  state2,
  openComparison,
  state3,
  getData,
  showWDCA,
}) => {
  return (
    <div id="chart-data" className="historical-doctor pb-0 circle-chart">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Case Acceptance</h6>
          </div>
          <div className="col-12 pb-3">
            <div className="summary-cards-container">
              {wdResModelCaseAcceptance != null &&
                wdResModelCaseAcceptance.length > 0 &&
                wdResModelCaseAcceptance.map((add, i) => (
                  <div className={i > 0 ? "doctor-production pl-2" : "doctor-production"}>
                    <div className="white-card  p-0">
                      <div className="d-flex align-items-center justify-content-between p-3 border-bottom height-85 white-card-header">
                        <h6 className="blue-txt pb-0 cursor-unset" onClick={() => detailPopUp(add)}>
                          {add.Title}{" "}
                          <img
                            alt={add.Tooltip}
                            src={logos.Info}
                            className="info-icon"
                            title={add.Tooltip}
                          />
                        </h6>
                        <p className="black-txt">{add.Value}</p>
                      </div>
                      <div className="d-flex  align-items-center p-3">
                        <div className="flex-fill">
                          <p className="black-txt black-txt-small">
                            {add.WidgetCharts != null &&
                              add.WidgetCharts.length > 0 &&
                              add.WidgetCharts[0].Title}
                          </p>
                          <Chart
                            onClick={() => openGoal(add)}
                            id="#chart-gradi"
                            options={state2.options}
                            series={
                              add.WidgetCharts.length > 0
                                ? getData(add.WidgetCharts[0].Value.slice(0, -1)).series
                                : getData(0).series
                            }
                            type="radialBar"
                            height={170}
                            width={120}
                          />
                        </div>
                        <div className="flex-fill">
                          <p className="black-txt black-txt-small">
                            {add.WidgetCharts != null &&
                              add.WidgetCharts.length > 1 &&
                              add.WidgetCharts[1].Title}
                          </p>
                          <Chart
                            onClick={() => openComparison(add)}
                            id="#chart-gradi1"
                            options={state3.options}
                            series={
                              add.WidgetCharts.length > 1
                                ? getData(add.WidgetCharts[1].Value.slice(0, -1)).series
                                : getData(0).series
                            }
                            type="radialBar"
                            height={170}
                            width={120}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className={!showWDCA ? "d-none" : ""}>
              <AppSectionLoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseAcceptance;
