import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Progress } from "reactstrap";
import Nouislider from "nouislider-react";
import wNumb from "wnumb";
import "nouislider/distribute/nouislider.css";
function Title_7(props) {
  const [comparisonModel, setComparisonModel] = useState({
    First: "1",
    Second: "0",
    Third: "0",
    Fourth: "0",
    Fifth: "0",
  });

  const [userData, setUserData] = useState(0);

  useEffect(() => {
    let obj = { ...comparisonModel };
    let comparison = props.selectedGoal != null &&
      props.selectedGoal.WidgetCharts != null &&
      props.selectedGoal.WidgetCharts.length > 0 && {
        ...props.selectedGoal.WidgetCharts[1],
      };
    if (
      comparison != null &&
      comparison.Goal != null &&
      comparison.Goal.length > 0
    ) {
      if (comparison.Goal.length > 0) {
        obj.First = comparison.Goal[0].Value.replace("$", "").replace(/,/g, "");
      }
      if (comparison.Goal.length > 1) {
        obj.Second = comparison.Goal[1].Value.replace("$", "").replace(
          /,/g,
          ""
        );
      }
      if (comparison.Goal.length > 2) {
        obj.Third = comparison.Goal[2].Value;
      }
      if (comparison.Goal.length > 3) {
        obj.Fourth = comparison.Goal[3].Value.replace("$", "").replace(
          /,/g,
          ""
        );
      }
      if (comparison.Goal.length > 4) {
        obj.Fifth = comparison.Goal[4].Value.replace("$", "").replace(/,/g, "");
      }

      if (comparison.Goal.length > 5) {
        setUserData(
          comparison.Goal[5].Value.replace("$", "").replace(/,/g, "")
        );
      }
    }
    setComparisonModel(obj);
  }, [props.selectedGoal]);

  const minValue = parseInt(comparisonModel.Fifth);
  const maxValue =
    parseInt(comparisonModel.First) == 0 ? 1 : parseInt(comparisonModel.First);
  // const midValue = (maxValue + minValue) / 2;
  const midValue = comparisonModel.Third;

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Production Cross-User Comparison
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <div className="user-slider">
            <Nouislider
              className="cross-user-slider"
              range={{ min: minValue, max: maxValue }}
              direction={"rtl"}
              orientation="horizontal"
              // start={[7765, 11452]}
              start={userData}
              // start='11452'
              connect
              tooltips={[
                wNumb({
                  decimals: 0,
                  thousand: ",",
                  prefix: "$",
                }),
                //   ,
                //   wNumb({
                //     decimals: 0,
                //     thousand: ',',
                //     prefix: '$'
                // })
              ]}
              pips={{
                mode: "steps",
                stepped: true,
                density: 4,
                format: wNumb({
                  decimals: 0,
                  thousand: ",",
                  prefix: "$",
                }),
              }}
            />
            <div className="mid-value">
              <span>{midValue}</span>
              <span className="value-label">Medium</span>
            </div>
          </div>

          {/* <form>
            <div className="row ">
              <div className=" col-12 ">
                <input type="text" className="form-control" id="email" placeholder="90th" name="email" />
              </div>
              <div className=" col-12 ">
                <input type="text" className="form-control" id="email" placeholder="75th" name="email"/>
              </div>
              <div className=" col-12 ">
                <input type="text" className="form-control" id="email" placeholder="50th" name="email"/>
              </div>
              <div className=" col-12 ">
                <input type="text" className="form-control" id="email" placeholder="25th" name="email"/>
              </div>
              <div className=" col-12 ">
                <input type="text" className="form-control" id="email" placeholder="10th" name="email"/>
              </div>

            </div>
            <div className="row">
              <div className="col-12 text-center">
                <button type="button" className="btn btn-outline-primary blue-btn-bg ">OK</button>
              </div>
            </div>
          </form> */}
          {/* <Progress multi>
        <Progress bar value="5" max={55}>5</Progress>
        <Progress bar color="success" value="15" max={55}>15</Progress>
        <Progress bar color="warning" value="10" max={55}>10</Progress>
        <Progress bar color="danger" value="10" max={55}>10</Progress>
      </Progress> */}
          {/* <Nouislider
            className="cross-user-slider"
            range={{
              min: parseInt(comparisonModel.Fifth),
              max:
                parseInt(comparisonModel.First) == 0
                  ? 1
                  : parseInt(comparisonModel.First),
            }}
            direction={"rtl"}
            start={[
              parseInt(comparisonModel.Fourth),
              parseInt(comparisonModel.Second),
            ]}
            connect
            tooltips={[
              wNumb({
                decimals: 0,
                thousand: ",",
                prefix: "$",
              }),
              wNumb({
                decimals: 0,
                thousand: ",",
                prefix: "$",
              }),
            ]}
            pips={{
              mode: "steps",
              stepped: true,
              density: 4,
              format: wNumb({
                decimals: 0,
                thousand: ",",
                prefix: "$",
              }),
            }}
          /> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Title_7;
